import React from 'react';
import './App.css';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
    name: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: 84,
        fontWeight: 500,
        color: 'rgb(51, 51, 51)',
        lineHeight: 1.1,
        marginBottom: 0,
        marginTop: 0,
    },
    '@media (max-height: 600px) and (orientation: portrait)': {
        headerSection: {
            marginTop: '2vh !important',
        },
    },
    headerSection: {
        marginTop: '10vh',
        textAlign: 'center',
        marginBottom: '5vh',
    },
    row: {},
    externalLinkSection: {
        display: 'flex',
        flexFlow: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
    socialMediaIcon: {
        color: 'black',
        margin: '0.25em',
        height: 70,
        marginBottom: 0,
        textDecoration: 'none',
    },
    resumeLink: {
        display: 'none',
        color: 'black',
        fontSize: '1em',
        textAlign: 'center',
    },
    tagline: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif,' +
            '"Helvetica Neue",Helvetica,Arial,sans-serif',
        fontSize: 14,
        color: 'rgb(51, 51, 51)',
    },
    linkImage: {
        borderRadius: 8,
        height: 60,
        width: 60,
        // backgroundColor: 'white',
    },
    linkParent: {
        width: 95,
        height: 150,
    },
    linkText: {
        marginTop: 8,
    }
})

const Name = function () {
    const classes = useStyles();
    return <h1 className={classes.name}>Louis Lepper</h1>;
}

const Tagline = function () {
    const classes = useStyles();

    const message = 'Striving to make the world a better place through code';
    return <p className={classes.tagline}>{message}</p>;
}

const HeaderSection = function () {
    const classes = useStyles();

    return <div className={classes.headerSection}><Name/><Tagline/></div>;
}

const Row = function (props: any) {
    const classes = useStyles();

    return <div className={classes.row}>{props.children}</div>;
}

const ExternalLinksSection = function () {
    const classes = useStyles();
    return <div className={classes.externalLinkSection}>
        <div className={classes.linkParent}>
            <a href="https://www.linkedin.com/in/louislepper"
               className={classes.socialMediaIcon }>
                <img alt={'LinkedIn'} className={classes.linkImage} src={'/linkedin.png'}/>
                <p className={classes.linkText}>LinkedIn</p>
            </a>
        </div>
        <div className={classes.linkParent}>
            <a href="https://github.com/louislepper"
               className={classes.socialMediaIcon}>
                <img alt={'Github'} className={classes.linkImage} src={'/github.png'}/>
                <p className={classes.linkText}>Github</p>
            </a>
        </div>
        <div className={classes.linkParent}>
            <a href="https://haveyoueaten.co/" className={classes.socialMediaIcon}>
                <img alt={'Have You Eaten Sydney'} className={classes.linkImage} src={'/haveyoueaten-new.png'}/>
                <p className={classes.linkText}>Have You Eaten Sydney</p>
            </a>
        </div>
        <div className={classes.linkParent}>
            <a href="https://play.google.com/store/apps/details?id=com.louislepper.waveform"
               className={classes.socialMediaIcon}>
                <img alt={'Waveform'} className={classes.linkImage} src={'/waveform.webp'}/>
                <p className={classes.linkText}>Waveform</p>
            </a>
        </div>
    </div>;
}

const Resume = function () {
    const classes = useStyles();

    return <div className={classes.resumeLink}><a href="resume.pdf">Resume</a></div>
}

const MainFrame = function () {
    return <div>
        <Row><HeaderSection/></Row>
        <Row><ExternalLinksSection/></Row>
        <Resume/>
    </div>;
}


function App() {
    return (
        <div className="App">
            <MainFrame/>
        </div>
    );
}

export default App;
